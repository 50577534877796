import axios from '@jumbo/utils/axiosHelper';
import url from '../../@jumbo/constants/UrlConstant';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import { loadDataPrice, setDataPrice, setPricePagination, setSearchMode } from '../../redux/actions';

const priceLoad = {
  getKhusus: () => {
    return (dispatch, getState) => {
      const { activePaginate, keyword,limit } = getState().price;
      dispatch(setDataPrice([]));
      dispatch(fetchStart());
      dispatch(loadDataPrice(true));
      let endpoin;
      if (keyword !== '') {
        dispatch(setSearchMode(true));
        endpoin = `${url.price.searchKhusus}?keyword=${keyword}&page=${activePaginate}&limit=${limit}`;
      } else {
        dispatch(setSearchMode(false));
        endpoin = `${url.price.khusus}?page=${activePaginate}&limit=${limit}`;
      }
      axios
        .get(endpoin)
        .then(({ data, status }) => {
          if (status === 200) {
            dispatch(fetchSuccess());
            dispatch(setDataPrice(data.price_khusus.data));
            dispatch(
              setPricePagination({
                current_page: data.price_khusus.current_page,
                per_page: data.price_khusus.per_page,
                total: data.price_khusus.total,
                last_page: data.price_khusus.last_page,
                from: data.price_khusus.from,
              }),
            );
          } else {
            dispatch(loadDataPrice(false));
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(loadDataPrice(false));
          dispatch(fetchError(error.message));
        });
    };
  },
  getUmum: () => {
    return (dispatch, getState) => {
      const { activePaginate, keyword } = getState().price;
      dispatch(setDataPrice([]));
      dispatch(fetchStart());
      dispatch(loadDataPrice(true));
      let endpoin;
      if (keyword !== '') {
        endpoin = `${url.price.searchUmum}?page=${activePaginate}&keyword=${keyword}`;
      } else {
        endpoin = `${url.price.umum}?page=${activePaginate}`;
      }
      axios
        .get(endpoin)
        .then(({ data, status }) => {
          if (status === 200) {
            dispatch(fetchSuccess());
            dispatch(setDataPrice(data.price_umum.data));
            dispatch(
              setPricePagination({
                current_page: data.price_umum.current_page,
                per_page: data.price_umum.per_page,
                total: data.price_umum.total,
                last_page: data.price_umum.last_page,
                from: data.price_umum.from,
              }),
            );
          } else {
            dispatch(loadDataPrice(false));
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(loadDataPrice(false));
          dispatch(fetchError(error.message));
        });
    };
  },
  getShowHide: () => {
    return (dispatch, getState) => {
      const { activePaginate, keyword, limit } = getState().price;
      dispatch(fetchStart());
      dispatch(loadDataPrice(true));
      let endpoin;
      if (keyword !== '') {
        endpoin = `${url.price.searchHidden}?keyword=${keyword}`;
      } else {
        endpoin = `${url.price.showHide}?page=${activePaginate}&limit=${limit}`;
      }
      axios
        .get(endpoin)
        .then(({ data, status }) => {
          if (status === 200) {
            dispatch(fetchSuccess());
            dispatch(setDataPrice(data.show_hide.data));
            dispatch(
              setPricePagination({
                current_page: data.show_hide.current_page,
                per_page: data.show_hide.per_page,
                total: data.show_hide.total,
                last_page: data.show_hide.last_page,
                from: data.show_hide.from,
              }),
            );
          } else {
            dispatch(loadDataPrice(false));
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          dispatch(loadDataPrice(false));
          dispatch(fetchError(error.message));
        });
    };
  },
  UpdateHide: params => {
    return (dispatch, getState) => {
      dispatch(fetchStart());
      dispatch(loadDataPrice(true));

      return axios
        .post(url.price.update, params)
        .then(({ data, status }) => {
          if (status === 200 && data.success === true) {
            dispatch(fetchSuccess());
            return { status: true, message: data.message };
          } else {
            dispatch(loadDataPrice(false));
            dispatch(fetchError(data.message));
            return { status: false, message: data.message };
          }
        })
        .catch(function(error) {
          dispatch(loadDataPrice(false));
          dispatch(fetchError(error.message));
          return { status: false, message: error.message };
        });
    };
  },
  deletePrice: params => {
    return (dispatch, getState) => {
      dispatch(fetchStart());
      dispatch(loadDataPrice(true));

      return axios
        .post(url.price.delete, params)
        .then(({ data, status }) => {
          if (status === 200 && data.success === true) {
            dispatch(fetchSuccess());
            return { status: true, message: data.message };
          } else {
            dispatch(loadDataPrice(false));
            dispatch(fetchError(data.message));
            return { status: false, message: data.message };
          }
        })
        .catch(function(error) {
          dispatch(loadDataPrice(false));
          dispatch(fetchError(error.message));
          return { status: false, message: error.message };
        });
    };
  },
};

export default priceLoad;
