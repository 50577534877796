import React from 'react';
import { PostAdd, Info, Store, MonetizationOn, CloudUpload, People, StarTwoTone } from '@material-ui/icons';
import IntlMessages from '../../../utils/IntlMessages';

export const sidebarNavs = [
  {
    name: 'Dashboard',
    type: 'section',
    children: [
      {
        name: 'Informasi',
        type: 'item',
        icon: <Info />,
        link: '/informasi',
      },
      {
        name: 'Cabang',
        type: 'item',
        icon: <Store />,
        link: '/cabang',
      },
      {
        name: 'Harga',
        type: 'item',
        icon: <MonetizationOn />,
        link: '/harga',
      },
      {
        name: 'Upload',
        type: 'item',
        icon: <CloudUpload />,
        link: '/add-upload',
      },
      {
        name: 'Hasil',
        type: 'item',
        icon: <StarTwoTone />,
        link: '/hasil',
      },
    ],
  },
  {
    name: 'Account',
    type: 'section',
    children: [
      {
        name: 'User',
        type: 'item',
        icon: <People />,
        link: '/user',
      },
    ],
  },
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];
