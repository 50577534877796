import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles, alpha } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import Tooltip from '@material-ui/core/Tooltip';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { Box, Button } from '@material-ui/core';
import priceLoad from '../../../services/price';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TablePagination from '@material-ui/core/TablePagination';
import { formatRupiah, formatPercent } from '../../../@jumbo/utils/formatHelper';

// import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@material-ui/icons/FilterList';
import { useSelector } from 'react-redux';
import { setKeywordPrice, setPriceLimit } from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { Refresh } from '@material-ui/icons';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: 'cabang', numeric: false, disablePadding: true, label: 'Cabang' },
  { id: 'nama_test', numeric: false, disablePadding: false, label: 'Nama Test' },
  { id: 'harga', numeric: true, disablePadding: false, label: 'Harga' },
  { id: 'diskon', numeric: true, disablePadding: false, label: 'Diskon' },
  { id: 'diskonRp', numeric: true, disablePadding: false, label: 'Diskon Rp' },
  { id: 'netto', numeric: true, disablePadding: false, label: 'Netto' },
];

const headCellsKhusus = [
  { id: 'kontraktor', numeric: false, disablePadding: true, label: 'Kontraktor' },
  { id: 'cabang', numeric: false, disablePadding: true, label: 'Cabang' },
  { id: 'nama_test', numeric: false, disablePadding: false, label: 'Nama Test' },
  { id: 'harga', numeric: true, disablePadding: false, label: 'Harga' },
  { id: 'diskon', numeric: true, disablePadding: false, label: 'Diskon' },
  { id: 'diskonRp', numeric: true, disablePadding: false, label: 'Diskon Rp' },
  { id: 'netto', numeric: true, disablePadding: false, label: 'Netto' },
];

const headCellsToggle = [
  { id: 'kode', numeric: false, disablePadding: true, label: 'Kode' },
  { id: 'nama', numeric: false, disablePadding: false, label: 'Nama' },
  { id: 'alamat', numeric: true, disablePadding: false, label: 'Alamat' },
  { id: 'telp', numeric: true, disablePadding: false, label: 'Telepon' },
];

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  selectContainer: {
    margin: '10px 0',
  },
  select: {
    padding: '8px 12px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: 'white',
    color: '#333',
    cursor: 'pointer',
    width: '200px',
  },
};
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, toggle, countMatch } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const { tabActive, searchMode } = useSelector(state => state.price);
  return (
    <TableHead className={classes.HeadTable}>
      <TableRow>
        {toggle || (!toggle && searchMode && tabActive === 1) ? (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={rowCount > 0 && countMatch < 10}
              checked={rowCount > 0 && countMatch === 10}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
        ) : null}

        {!toggle &&
          tabActive === 0 &&
          headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}

        {!toggle &&
          tabActive === 1 &&
          headCellsKhusus.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}

        {toggle &&
          headCellsToggle.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, handleUpdate, tabActive } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}>
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Fragment />
      )}

      {numSelected > 0 && tabActive !== 1 ? (
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          className={classes.button}
          startIcon={<SaveIcon />}
          onClick={handleUpdate}>
          Simpan
        </Button>
      ) : (
        <Fragment />
      )}

      {numSelected > 0 && tabActive === 1 ? (
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          className={classes.button}
          startIcon={<DeleteIcon />}
          onClick={handleUpdate}>
          Hapus
        </Button>
      ) : (
        <Fragment />
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  HeadTable: {
    backgroundColor: '#fafafa',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#F0F4F9',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginBottom: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '27px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(220, 220, 220, 0.5)',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function EnhancedTable({ toggle, last_page, page = 1, HandlePagination, HandleRowPagination, limit = 10 }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [selectedFlag, setSelectedFlag] = React.useState([]);
  const [selectedKhusus, setSelectedKhusus] = React.useState([]);
  const [toggleSelectedAll, setToggleSelectedAll] = React.useState(false);
  // const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [keyword, setKeyword] = React.useState('');
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);

  const { priceData, tabActive, searchMode } = useSelector(state => state.price);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event, action = 'umum') => {
  //   alert('handleSelectAllClick' + event.target.checked);
  //   if (event.target.checked) {
  //     if (action === 'khusus') {
  //       const selectAll = priceData.map(n => n.kodetest);
  //       setSelected([...selected, ...selectAll]);
  //       console.log(selectAll, 'selectAll');
  //       // const newSelectedsFlag = priceData.map(n => {
  //       //   return [n.kode_cabang, n.kode_kontrak, n.kodetest];
  //       // });
  //       // setSelectedKhusus([...selectedKhusus, ...newSelectedsFlag]);
  //       return;
  //     } else {
  //       const newSelecteds = priceData.map(n => n.kode);
  //       setSelected([...selected, ...newSelecteds]);
  //       console.log(newSelecteds, 'newSelecteds');
  //       const newSelectedsFlag = priceData.map(n => (n.show_harga === 'TRUE' ? 'FALSE' : 'TRUE'));
  //       setSelectedFlag([...selectedFlag, ...newSelectedsFlag]);
  //       return;
  //     }
  //   }
  //   setSelected([]);
  //   setSelectedFlag([]);
  // };

  const handleSelectAllClickKhusus = event => {
    if (event.target.checked) {
      const selectAll = priceData.map(n => n.kode_cabang + n.kode_kontrak + n.kodetest);
      setSelected([...selected, ...selectAll]);
      const newSelectedsFlag = priceData.map(n => {
        return [n.kode_cabang, n.kode_kontrak, n.kodetest];
      });
      setSelectedKhusus([...selectedKhusus, ...newSelectedsFlag]);
      return;
    }
    setSelected([]);
    setSelectedKhusus([]);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = priceData.map(n => n.kode);
      setSelected([...selected, ...newSelecteds]);
      const newSelectedsFlag = priceData.map(n => (n.show_harga === 'TRUE' ? 'FALSE' : 'TRUE'));
      setSelectedFlag([...selectedFlag, ...newSelectedsFlag]);
      return;
    }
    setSelected([]);
    setSelectedFlag([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row?.kode);
    let newSelected = [];
    let newselectedFlag = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row?.kode);
      newselectedFlag = newselectedFlag.concat(selectedFlag, row?.show_harga === 'TRUE' ? 'FALSE' : 'TRUE');
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newselectedFlag = newselectedFlag.concat(selectedFlag.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newselectedFlag = newselectedFlag.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      newselectedFlag = newselectedFlag.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
    setSelectedFlag(newselectedFlag);
  };

  const handleClickKhusus = (event, row) => {
    const selectedIndex = selected.indexOf(row?.kode_cabang + row?.kode_kontrak + row?.kodetest);
    let newSelected = [];
    let newSelectedData = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row?.kode_cabang + row?.kode_kontrak + row?.kodetest);
      newSelectedData = newSelectedData.concat(selected, [row?.kode_cabang, row?.kode_kontrak, row?.kodetest]);
      // newSelectedData = newSelectedData.push(...selected, ...[row?.kode_cabang, row?.kode_kontrak, row?.kodetest]);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedData = newSelectedData.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedData = newSelectedData.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      newSelectedData = newSelectedData.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    console.log(newSelected, 'newSelectedData');

    setSelected(newSelected);
    setSelectedKhusus(newSelectedData);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row', // Mengubah arah tata letak menjadi horizontal
      justifyContent: 'flex-start', // Menempatkan elemen ke paling kiri
      alignItems: 'center',
      gap: '20px',
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      marginRight: '20px',
    },
    select: {
      padding: '8px 12px',
      fontSize: '16px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      backgroundColor: 'white',
      color: '#333',
      cursor: 'pointer',
      width: '143px',
    },
  };
  const handleChangeKeyword = event => {
    setKeyword(event.target.value);
  };

  const searchAction = () => {
    dispatch(setKeywordPrice(keyword));
  };


const handleChangeRowsPerPage = (event) => {
  dispatch(setPriceLimit(event.target.value))
  setSelected([]);
  setSelectedKhusus([]);
};
  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, last_page - page * rowsPerPage);

  const handleUpdate = async () => {
    const params = { id: selected, show_harga: selectedFlag };
    const result = await dispatch(priceLoad.UpdateHide(params));
    // console.log(result, 'result');
    if (result.status === true) {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      }).then(() => {
        setSelected([]);
        dispatch(priceLoad.getShowHide());
        return true;
      });
    }
  };

  const handleDelete = async () => {
    const buildParam = [];

    priceData.forEach((item, index) => {
      if (selected.includes(item.kode_cabang + item.kode_kontrak + item.kodetest)) {
        buildParam.push([item?.kode_cabang, item?.kode_kontrak, item?.kodetest]);
      }
    });

    const params = { id: [buildParam] };
    const result = await dispatch(priceLoad.deletePrice(params));

    if (result.status === true) {
      await MySwal.fire({
        title: <p>{result.message}</p>,
        icon: 'success',
      }).then(() => {
        setSelected([]);
        dispatch(priceLoad.getKhusus());
        return true;
      });
    }
  };

  return (
    <div className={classes.root}>
      <Box className={classes.searchContainer}>
      {!toggle && tabActive === 1 && searchMode && (
          <div  style={styles.container}>
                  <div style={styles.selectContainer}>

                <select onChange={handleChangeRowsPerPage} style={styles.select}>
                  <option value={10}>10 Perpage</option>
                  <option value={100}>100 Perpage</option>
                  <option value={250}>250 Perpage</option>
                  <option value={500}>500 Perpage</option>
                  <option value={750}>750 Perpage</option>
                </select>
                </div>
            </div>
        )}
        <div className={classes.search}>
         
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleChangeKeyword}
          />
        </div>
        <div className={classes.searchIcon} >
          <IconButton aria-label="search" className={classes.margin} size="small" onClick={searchAction}>
            <SearchIcon fontSize="inherit" />
          </IconButton>
        </div>
      </Box>
     
      <Paper className={classes.paper} elevation={0}>
        {toggle && <EnhancedTableToolbar numSelected={selected.length} handleUpdate={handleUpdate} />}
        {!toggle && tabActive === 1 && searchMode && (
          <EnhancedTableToolbar numSelected={selected.length} handleUpdate={handleDelete} tabActive={tabActive} />
        )}

        <TableContainer style={{ maxHeight: '68vh' }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table">
            <EnhancedTableHead
              toggle={toggle}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={toggle ? handleSelectAllClick : handleSelectAllClickKhusus}
              onRequestSort={handleRequestSort}
              rowCount={priceData.length}
              countMatch={
                priceData.filter(data =>
                  toggle
                    ? selected.includes(data.kode)
                    : selected.includes(data.kode_cabang + data.kode_kontrak + data.kodetest),
                ).length
              }
            />
            <TableBody>
              {!toggle &&
                tabActive === 0 &&
                stableSort(priceData, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    console.log(row, 'row');
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={event => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.nama}
                        selected={isItemSelected}>
                        {/* <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                        </TableCell> */}
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.cabang || row.nama}
                        </TableCell>
                        <TableCell align="right">{row.nama_test}</TableCell>
                        <TableCell align="right">{formatRupiah(row.harga, 'Rp. ')}</TableCell>
                        <TableCell align="right">{formatPercent(row.diskon_persen)}</TableCell>
                        <TableCell align="right">{formatRupiah(row.diskon_rupiah, 'Rp. ')}</TableCell>
                        <TableCell align="right">{formatRupiah(row.netto, 'Rp. ')}</TableCell>
                      </TableRow>
                    );
                  }
                )}
        
          {!toggle &&
                tabActive === 1 &&
                stableSort(priceData, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  
                  .map((row, index) => {
                    console.log(row, 'row1');
                    const isItemSelected = isSelected(row.kode_cabang + row.kode_kontrak + row.kodetest);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={event => handleClickKhusus(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.kode_cabang + row.kode_kontrak + row.kodetest}
                        selected={isItemSelected}>
                        {!toggle && tabActive === 1 && searchMode && (
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                          </TableCell>
                        )}

                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.kontraktor}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.cabang || row.nama}
                        </TableCell>
                        <TableCell align="right">{row.nama_test}</TableCell>
                        <TableCell align="right">{formatRupiah(row.harga, 'Rp. ')}</TableCell>
                        <TableCell align="right">{formatPercent(row.diskon_persen)}</TableCell>
                        <TableCell align="right">{formatRupiah(row.diskon_rupiah, 'Rp. ')}</TableCell>
                        <TableCell align="right">{formatRupiah(row.netto, 'Rp. ')}</TableCell>
                      </TableRow>
                    );
                  }
              )}
              {toggle &&
                stableSort(priceData, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    console.log(row, 'row2');
                    const isItemSelected = isSelected(row.kode);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={event => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.kode}
                        selected={isItemSelected}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.kode}
                        </TableCell>
                        <TableCell align="right">{row.nama}</TableCell>
                        <TableCell align="right">{row.alamat}</TableCell>
                        <TableCell align="right">{row.telp}</TableCell>
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {toggle && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={last_page}
            rowsPerPage={limit}
            page={page - 1}
            onPageChange={(e, c) => HandlePagination(e, c + 1)}
            onRowsPerPageChange={(e, c) => HandleRowPagination(e, c)}
          />
        )}
      </Paper>
    </div>
  );
}
