import {
	SET_HASIL_DATA,
	SET_ACTIVE_HASIL_PAGINATION,
	SET_HASIL_PAGINATION,
	SET_HASIL_KEYWORD,
  } from '../../@jumbo/constants/ActionTypes';
  
  export const getHasilList = data => {
	return dispatch => {
	  dispatch({
		type: SET_HASIL_DATA,
		payload: data,
	  });
	};
  };
  
  export const setActiveHasilPagination = pagination => {
	return dispatch => {
	  dispatch({
		type: SET_ACTIVE_HASIL_PAGINATION,
		payload: pagination,
	  });
	};
  };
  
  export const setHasilPagination = pagination => {
	return dispatch => {
	  console.log('pagination',pagination)
	  dispatch({
		type: SET_HASIL_PAGINATION,
		payload: pagination,
	  });
	};
  };
  
  export const setKeywordHasil = keyword => {
	return dispatch => {
	  dispatch({
		type: SET_HASIL_KEYWORD,
		payload: keyword,
	  });
	};
  };
  